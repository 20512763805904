import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BrandGrid from '../components/BrandGrid/BrandGrid';
import Seo from '../components/Seo';

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404: Not Found" />
      <BrandGrid />
      <div className="d-flex align-items-lg-center full-height-lg ui-layout__main--nmt">
        <Container>
          <Row className="align-items-center justify-content-lg-between text-center text-lg-start">
            <Col lg={6}>
              <h1 className="h1--not-decorated font-size-xl">Page not found</h1>
              <p className="mt-2 font-size-lg">
                Sorry we couldn’t find what you were looking for.
              </p>
              <div className="my-5">
                <h4 className="mb-2">Need help finding something?</h4>
                <p className="mb-0">
                  Email info@medicalwebexperts.com and we’ll help you.
                </p>
              </div>

              <Row className="align-items-center justify-content-center justify-content-lg-start">
                <Col className="col-auto">
                  <Button as={Link} variant="primary" to="/contact-us/">
                    Contact Us
                  </Button>
                </Col>
                <Col className="col-auto">
                  <Button
                    as={Link}
                    variant="link"
                    to="/"
                    className="text-decoration-none"
                  >
                    Homepage
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NotFoundPage;
